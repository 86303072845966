import React from 'react'
import ReactWOW from 'react-wow'
import { Link } from 'gatsby'
import TalentServicesSidebar from './TalentServicesSidebar'
import details1 from '../../assets/images/services/services-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

import starIcon from '../../assets/images/star-icon.png'
import icon4 from '../../assets/images/icons/icon4.png'
import icon5 from '../../assets/images/icons/icon5.png'
import icon6 from '../../assets/images/icons/icon6.png'
import icon7 from '../../assets/images/icons/icon7.png'
import shape1 from '../../assets/images/shape/circle-shape1.png'
import disqualify from '../../assets/images/disqualify.png'

const TalentScoutingDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="talent-scout-content">
                            <span className="sub-title">
                                <img src={starIcon} alt="banner" /> 
                                Push Quality To The Edge
                            </span>
                            <h3>Quality Over Quantity</h3>

                            <p>We send you candidates with confidence the profile is relevant to you.<br/>
                            Your first interview should be a hit, not a miss!</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Our Recipe</h3>
                                        <ul>
                                            <li>Upskilled Talent Team</li>
                                            <li>Initial Screening by Industry Experts</li>
                                            <li>Informative Summary with Screening Notes</li>
                                            <li>Adapting to Your Needs Early</li>
                                            <li>Open CV Policy for Trusted Clients</li>
                                        </ul>
                                    </div>

                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>
                            </div>

                            <p>Our Talent team is upskilled in Dev, DevOps, Data Analytics, Security and Privacy.<br />Initial 30m pre-screening by our Tech Experts ensures the candidates level of expertise.</p>

                            <hr />

                            <span className="sub-title">
                                <img src={starIcon} alt="banner" /> 
                                Save Time To Reach The Best
                            </span>
                            <h3>Reduce Time Wasted</h3>

                            <p>We work together with your HR and Tech Teams to understand <strong>your</strong> requirements and blend them early into our conversation with the candidate to provide you what your teams deserve most - a qualified candidate.</p>

                            <div className="row align-items-center">
                                <div className="col-lg-4 col-md-6">
                                    <div className="image">
                                        <img src={disqualify} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-8 col-md-6">
                                    <div className="content">
                                        <h3>Your Time Eaters</h3>
                                        <ul>
                                            <li>Over 25% of applicants do not have a matching skillset</li>
                                            <li>Over 25% of applicants do not have enough experience</li>
                                            <li>Over 25% of applicants do not meet location requirements</li>
                                            <li>Over 25% of applicants do not meet language requirements</li>
                                            <li>Over 10% of applicants simply do not reply</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>

                            <p>With us you can focus on candidates that matter!</p>

                            <hr />

                            <span className="sub-title">
                                <img src={starIcon} alt="banner" /> 
                                Get To Know Us
                            </span>
                            <h3>We Are Here</h3>

                            <div className="row align-items-center">
                                <div className="col-lg-12 col-md-12">
                                    <div className="content">
                                        Reach out to us via <Link to="https://tawk.to/chat/5f8189974704467e89f64817/default"><strong>website chat on the side</strong></Link> or <Link to="/contact"><strong>other possible ways to reach us</strong></Link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <TalentServicesSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TalentScoutingDetailsContent
