import React from 'react'
import {Link} from 'gatsby'

import talentScountingStandardTerms from '../../assets/pdf/Noroutine_Talent_Scouting_Terms_11_2020.pdf'
import sampleDevOpsEngineer from '../../assets/pdf/Noroutine_DevOps_Engineer.pdf'
import sampleBigDataDevOpsEngineer from '../../assets/pdf/Noroutine_BigDataDevOps_Engineer.pdf'
import flyer from '../../assets/pdf/Noroutine_Talent_Scouting_Flyer.pdf'

const TalentServiceSidebar = () => {
    return (
        <div className="services-details-info">

            <div className="download-file">
                <h3>Don't Miss!</h3>

                <ul>
                    <li>
                        <a href={talentScountingStandardTerms} target="_blank" rel="noreferrer">
                            Our Standard Terms<i className='bx bxs-file-pdf'></i>
                        </a>
                    </li>
                    <li>
                        <a href={flyer} target="_blank" rel="noreferrer">
                            Flyer <i className='bx bxs-file-pdf'></i>
                        </a>
                    </li>
                    <li>
                        <a href={sampleDevOpsEngineer} target="_blank" rel="noreferrer">
                            Candidate (DevOps)<i className='bx bxs-file-pdf'></i>
                        </a>
                    </li>
                    <li>
                        <a href={sampleBigDataDevOpsEngineer} target="_blank" rel="noreferrer">
                            Candidate (Big Data DevOps)<i className='bx bxs-file-pdf'></i>
                        </a>
                    </li>
                </ul>
            </div>

        </div>
    )
}

export default TalentServiceSidebar
