import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import TalentBanner from "../components/Talent/Banner"
import Footer from "../components/App/Footer"
import TalentScoutingDetailsContent from '../components/Talent/TalentScoutingDetailsContent'

const TalentDetails = () => {
    return (
        <Layout>
            <Navbar />
{/*
            <PageBanner
                pageTitle="Talent Scouting" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Talent Scouting" 
            />
*/}

            <TalentBanner />
            <TalentScoutingDetailsContent />
            <Footer />
        </Layout>
    );
}

export default TalentDetails